<template>
    <v-row>
        <v-col cols="12" lg="3">
            <base-card>
                <v-card-text>
                    <div class="d-flex align-center justify-center mb-6">
                        <div class="text-center">
                            <v-img
                                height="128"
                                width="128"
                                :src="image_user"
                                class=" rounded-circle"
                                alt=""
                            ></v-img>
                            <h5 class="font-weight-bold my-3">
                                Code:    {{ client.code_client }}
                            </h5>
                           
                            <v-chip
                                label
                                small
                                class="ma-2"
                                color="orange"
                                text-color="white"
                            >
                                En attente
                            </v-chip>
                        </div>
                    </div>
                    <v-divider class="my-4" />
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                            Nom :
                        </p>
                        <div class="flex-1">
                           
                            <p class="mt-2 mb-0 flex-1">
                                {{ client.nom }}
                        </p>
                        </div>
                    </div>
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                           Prenoms :
                        </p>
                        <div class="flex-1">
                            
                            <p class="mt-2 mb-0 flex-1">
                                {{ client.prenom }}
                        </p>
                        </div>
                    </div>
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                            Date de naissance :
                        </p>
                        <div class="flex-1">
                                
                             <p class="mt-2 mb-0 flex-1">
                                    {{ client.date_naissance }}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                            Lieu de naissance :
                        </p>
                        <div class="flex-1">
                            
                            <p class="mt-2 mb-0 flex-1">
                                {{ client.lieu_naissance }}
                        </p>
                        </div>
                    </div>
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                            Telephone :
                        </p>
                        <div class="flex-1">
                            
                            <p class="mt-2 mb-0 flex-1">
                                {{ client.telephone }}
                        </p>
                        </div>
                    </div>
                    <div class="d-flex align-end justify-space-between mb-4">
                        <p class="mt-2 mb-0 flex-1">
                            Numero whatsapp :
                        </p>
                        <div class="flex-1">
                            
                            <p class="mt-2 mb-0 flex-1">
                                {{ client.number_whatsapp }}
                        </p>
                        </div>
                    </div>
                    <v-divider class="my-4" />
                    <div class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Solde actuel:
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                 {{ client.soldes }} XOF
                            </v-chip>
                        </div>
                    </div>
                </v-card-text>
            </base-card>
            <base-card>
                <v-card-text>
                    <div class="d-flex justify-space-between itesm-center">
                        <h5 class="ma-0">
                            Inbox
                        </h5>
                        <v-btn color="primary" class="rounded-pill" depressed>
                            View All
                        </v-btn>
                    </div>
                </v-card-text>
            </base-card>
        </v-col>
        <v-col cols="12" lg="9">
            <div class="d-flex align-center">
                <p class="font-weight-medium mr-3 mb-3">
                    Information du client et autres détails
                </p>
                <v-divider />
            </div>

            <base-card class="mb-4">
                <v-card-text class="">
                    <div class="d-flex align-center justify-space-between">
                        <div>
                            <div class="card-title">
                                Observation
                            </div>
                            <p class=" font-weight-medium">
                                
                            </p>
                        </div>
                        <div>
                            <v-img
                                width="128"
                                height="128"
                                class="d-sm-none d-md-block"
                                src="@/assets/images/illustrations/business_deal.svg"
                                alt=""
                            ></v-img>
                        </div>
                    </div>
                </v-card-text>
            </base-card>

            <base-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="card-title mb-0">
                                Les documents des clients
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="primary--text mb-2"
                                >mdi-passport-biometric</v-icon
                            >
                            <p class="heading-label ma-0 ">Carte information</p>
                            <p class="font-weight-bold body-1 mb-2">
                                Seul document autoriser par cette section (Carte d'idendité, passeport, permis de conduire, RAVIP OU NPI)
                              
                               
                            </p>
                            <v-btn class="" outlined color="primary" @click="dialog_carte = true"
                                >Consulter</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="info--text mb-2"
                                >mdi-image-multiple-outline</v-icon
                            >
                            <p class="heading-label ma-0 ">
                                Profil + photo carte d'idendité
                            </p>
                            <p class="font-weight-bold body-1 mb-2">
                                Le document  doit repondre a certaines conditions:
                                <ul>
                                    <li>Le document doit contenu la photo du client et la carte d'identité</li>
                                    <li>Le document doit etre en format image</li>
                                    
                                </ul>
                            </p>
                            <v-btn class="" outlined color="primary " @click="dialog_carte2 = true"
                                >Consulter</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="info--text mb-2"
                                >mdi-city</v-icon
                            >
                            <p class="heading-label ma-0 ">Adresse</p>
                          
                            <p class="font-weight-bold body-1 mb-2">
                                Le document  doit repondre a certaines conditions:
                                <ul>
                                    <li>Le document doit contenu l'adresse du client</li>
                                    <li>Le document doit etre en format image</li>
                                    
                                </ul>
                            </p>
                            <v-btn class="" outlined color="primary" @click="dialog_carte3= true"
                                >Consulter</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </base-card>

            <v-row>
                <v-col cols="12">
                    <base-card class="h-full py-5">
                        <v-card-text class="d-flex align-start ">
                            <v-icon color="primary" class="mr-2"
                                >mdi-lightbulb-on-outline</v-icon
                            >
                            <div>
                                <h5 class="font-weight-bold body-1">
                                    Tip #1: Utiliser les status afin de suivre le client
                                </h5>
                              
                                <v-select
                                    :items="list_status"
                                     v-model="status"
                                    outlined
                                    label="Status"
                                    placeholder="Status"
                                    class="mt-3"
                                ></v-select>
                                <v-col>

                                    <ckeditor
                                    
                                    v-model="editorData"
                                    :config="editorConfig"
                                    @change="onChange($event)"
                                ></ckeditor>

                                </v-col>

                                
                                <v-btn class="mt-3" color="primary"
                                    ><v-icon>
                                        mdi-send
                                    </v-icon> Envoyer</v-btn>
                                 
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col>
              
                

            </v-row>
        </v-col>
        <v-dialog v-model="dialog_carte" max-width="100%">
            <img v-img :src="img1">
        </v-dialog>
        <v-dialog v-model="dialog_carte2" max-width="100%">
            <img v-img :src="img2">
        </v-dialog>
        <v-dialog v-model="dialog_carte3" max-width="100%">
            <img v-img :src="img3">
        </v-dialog>

    </v-row>
    
</template>
<script>
import api from '@/serviceApi/apiService'

export default {

    name: "Profil Client",

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profil Client'
    },
    props: ['id','key'],
  
    data() {
        return {
            dialog_carte: false,
            dialog_carte2: false,
            dialog_carte3: false,
            img1: '',
            img2:'',
            img3:'',
            client: {
                nom: 'FAKO',
                prenom: 'Ismael',
                email: 'fakoismo@gmail.com',
                telephone: '+22900000',
                date_naissance: '31/08/1994',
                lieu_naissance: '',
                number_whatsapp: '229000',
                soldes: '0',
                code_client: '0000000000',

                },
                list_status: ['','Valider', 'Refuser'],
                image_user:'@/assets/user.png',

                list_document:[],
                urls_const: 'http://localhost:8000/storage/',
                
          
        }
    },
    mounted() {
        this.getClient()
    },
    methods: {

        async getClient(){
            const encryptedText_key=this.$CryptoJS.AES.decrypt(this.key, "Safajis2023").toString(this.$CryptoJS.enc.Utf8)
            const encryptedText_id=this.$CryptoJS.AES.decrypt(this.id, "Safajis2023").toString(this.$CryptoJS.enc.Utf8)
            
            const data = await api.readAll('backoffice/only-clients-kyc-personnel/'+encryptedText_id);

            if(data.length > 0){
                
                data.forEach(element => {
                    if(element.key == encryptedText_key){
                        if(element.personnel == null){
                            
                        }else{
                            //this.list_document = element.personnel.ficheClient
                            if(element.personnel.ficheClient==null){
                                this.img1 = this.image_user
                                this.img2 = this.image_user
                                this.img3 = this.image_user}
                            else{
                            this.img1 = this.urls_const+element.personnel.ficheClient[0].url_doc
                            this.img2 = this.urls_const+element.personnel.ficheClient[1].url_doc
                            this.img3 = this.urls_const+element.personnel.ficheClient[2].url_doc
                            }
                        this.client={
                            nom: element.personnel.noms,
                            prenom: element.personnel.prenoms,
                            email: element.email,
                            telephone: element.tel,
                           // date_naissance: element.date_naissance,
                            //lieu_naissance: element.lieu_naissance,
                            number_whatsapp: element.tel_whatsapp,
                            soldes: element.solde,
                            code_client: element.key,
                        }
                    }
                }
                });
                
                
            }



        }
    }
}
</script>
